import React, {Component} from 'react';
import {connect} from 'react-redux';
import bem from 'b_';
import {withTranslation} from 'react-i18next';

import Slider from 'components/slider';
import GalleryComponent from 'components/gallery';
import BreadCrumbs from 'components/breadcrumbs';

import {getGalleries} from '../../reducers/hotel-info';

import './style.scss';

const block = bem.with('photo-gallery');

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            names: null,
            galleries: null
        };
        this.galleries = React.createRef();
        this.names = React.createRef();
    }

    componentDidMount() {
        this.props.getGalleries();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.galleries && this.props.galleries.length) {
            if (this.galleries.current && !this.state.galleries) {
                this.setState({
                    names: this.names.current,
                    galleries: this.galleries.current
                });
            }
        }
    }

    render() {
        const {galleries, hotelInfo, t} = this.props;

        if (!galleries || galleries.length === 0) {
            if (!hotelInfo || !hotelInfo.images || hotelInfo.images.length === 0) {
                return (
                  <div className={'route-page'}>Loading...</div>
                );
            } else {
                galleries.push(hotelInfo);
            }
        }

        const nameSliderSettings = {
            arrows: false,
            slidesToShow: 2,
            asNavFor: this.galleries.current,
            infinite: true,
            focusOnSelect: true,
            centerMode: true,
            className: 'names-slider',
        };

        const galleriesSliderSettings = {
          asNavFor: this.names.current,
          arrows: false,
        };

        return (
            <section className={`route-page ${block()}`}>
                <BreadCrumbs title={t('Photo gallery')} />
                <section className={'categories-wrapper'}>
                    {galleries.length > 1 && (
                      <Slider sliderSettings={nameSliderSettings} setRef={this.names}>
                        {galleries.map((gallery) => {
                            return (
                              <div className={'names-slide'} key={gallery.id}>
                                  <span>{gallery.title}</span>
                              </div>
                            );
                        })}
                      </Slider>
                    )}
                    <Slider sliderSettings={galleriesSliderSettings} setRef={this.galleries}>
                        {galleries.map((gallery) => {
                          const sliderSettings = {
                            customPaging: (i) => {
                              const img = gallery.images[i];
                              return (
                                <img alt={img.title || gallery.title} src={img.src} />
                              );
                            },
                            dots: true,
                            dotsClass: `slick-dots slick-thumb ${block('thumbs')}`,
                          };
                          return (
                            <GalleryComponent key={gallery.id}
                                              gallery={gallery.images}
                                              sliderSettings={sliderSettings}
                                              title={gallery.title}
                                              subtitle={gallery.subtitle} />
                          );
                        })}
                    </Slider>
                </section>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        galleries: state.hotelInfo.galleries,
        hotelInfo: state.hotelInfo.hotelInfo
    };
};

const mapDispatchToProps = {
    getGalleries
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Gallery));