import React from 'react';


export default class TravelLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.script = document.createElement('script');
    this.script.src = '/js/booking.js';
    this.script.async = true;
    this.script.type = 'text/javascript';
  }

  componentDidMount() {
    document.body.appendChild(this.script);
  }

  render() {
    return (
      <div className={'hotel-booking'} id={'main-booking'}>
        <div className={'tl-container'}>
          <div id={'tl-booking-form'} />
        </div>
      </div>
    );
  }
}