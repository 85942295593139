import update from 'react-addons-update';

import {actionTypes} from '../store';

const initState = {
  countries: [],
  cities: [],
  pages: [],
  language: undefined,
  scripts: []
};

const cache = (state = initState, action) => {
  if (action.type === actionTypes.SET_COUNTRIES) {
    const {objects} = action.payload;
    return update(state, {
      countries: {
        $set: objects
      }
    });
  } else if (action.type === actionTypes.SET_CITIES) {
    const {objects} = action.payload;
    return update(state, {
      cities: {
        $set: objects
      }
    });
  } else if (action.type === actionTypes.SET_MENU_PAGES) {
    const {objects} = action.payload;
    return update(state, {
      pages: {
        $set: objects
      }
    });
  } else if (action.type === actionTypes.SET_LANGUAGE) {
    const {language} = action.payload;
    return update(state, {
      language: {
        $set: language
      }
    });
  } else if (action.type === actionTypes.SET_PAGE_SCRIPTS) {
    const {scripts} = action.payload;
    return update(state, {
      scripts: {
        $set: scripts
      }
    });
  } else {
    return state;
  }
};

export default cache;