import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import bem from 'b_';

import {getUser, loginUser} from "../../actions";
import './style.scss';

const block = bem.with('app-form');

class Login extends Component {
    static defaultProps = {
        errors: {},
        token: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            csrfmiddlewaretoken: ''
        };
        this.form = React.createRef();
    }
    componentDidMount() {
        const {user} = this.props;
        if (user) {
            this.props.history.push('/account');
        } else {
            this.props.getUser();
        }
    }

    componentDidUpdate() {
        const {token, user} = this.props;
        if (user) {
            this.props.history.push('/account');
            return;
        }
        if (token !== this.state.csrfmiddlewaretoken) {
            this.setState({csrfmiddlewaretoken: token});
        }
    }

    renderErrors(name) {
        const {errors} = this.props;

        if (errors.hasOwnProperty(name)) {
          return errors[name].map((err) => {
              return (
                <span className={block('error')}>{err}</span>
              );
          });
        }
    }

    onSubmit = (event) => {
        event.preventDefault();
        const formData = new URLSearchParams();
        Object.entries(this.state).forEach(([key, value]) => {
            formData.append(key, value);
        });
        this.props.loginUser(formData);
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    };

    render() {

        return (
            <section className={'route-page'}>
                <section className="app-bread-crumbs">
                    <ul>
                        <li><FontAwesomeIcon icon={['fas', 'chevron-right']}/></li>
                        <li><Link to="/">Главная</Link></li>
                        <li>/</li>
                        <li>Авторизация</li>
                    </ul>
                </section>
                <div className={'heading'}>
                    <h2>Авторизация</h2>
                    <div className={'app-heading-title-text'}>
                        <span className={'app-heading-line-left'} />
                        <span className={'app-heading-line-text'}>Доступ к истории бронирований</span>
                        <span className={'app-heading-line-right'} />
                    </div>
                </div>
                <div className={'app-login'}>
                <div className={block('container')}>
                    {this.props.user ? (
                      <div><Link to={'/account'}>Личный кабинет</Link></div>
                    ) : (
                    <form className={block()} action={'/api/users/login'} method={'POST'} onSubmit={this.onSubmit}>
                        {this.renderErrors('__all__')}
                        <input type={'hidden'} name={'csrfmiddlewaretoken'} value={this.state.csrfmiddlewaretoken} />
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="username">Имя пользователя</label>
                            <div className={block('input')}>
                                <input id="username" name="username" placeholder="Имя пользователя" required type="text"
                                       value={this.state.username} onChange={this.handleInputChange}/>
                                {this.renderErrors('username')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="password">Пароль</label>
                            <div className={block('input')}>
                                <input type="password" id="password" name="password" placeholder="Пароль" required
                                       value={this.state.password} onChange={this.handleInputChange}/>
                                {this.renderErrors('password')}
                            </div>
                        </div>
                        <div className={block('group', {actions: true})}>
                            <button className={'action'} tabIndex={3}>Войти</button>
                        </div>
                        <span>Не зарегистрированы? <Link to="/register">Создайте акаунт</Link></span>
                    </form>
                    )}
                </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        errors: state.user.errors,
        token: state.user.token
    };
};

const mapDispatchToProps = {
    getUser,
    loginUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);