import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import bem from "b_";
import {withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

// import logo from 'images/chg.png';
import {setCookie} from '../../lib';
import {setHotel} from 'actions/index';
import Booking from 'components/booking';
import Callback from 'components/callback';

import './style.scss';
// https://github.com/gosquared/flags
import ruFlag from 'images/Russia.png';
import enFlag from 'images/United-Kingdom.png';

const block = bem.with('app-header');

const languages = [{
    code: 'ru',
    title: 'Русский',
    flag: ruFlag
}, {
    code: 'en',
    title: 'English',
    flag: enFlag
}];
const hotels = ['Отель «Бристоль»'];

class Header extends Component {
    static defaultProps = {
        onLanguageSelect: () => {},
        lang: 'ru',
        pages: []
    };

    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.callback = React.createRef();
        this.state = {
            isLangOPen: false,
            isHotelOPen: false,
            isAuthOpen: false,
            isMenuOpen: true,
            isTopVisible: false,
            lang: props.i18n.language,
            _hotel: "Отель «Бристоль»",
            index: 0
        }
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        this.props.setHotel(this.state._hotel);
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleScrolling);
        this.props.history.listen((location, action) => {
            if (action === 'PUSH') {
                this.setState((state) => {
                    return {index: state.index+1};
                });
            } else if (action === 'POP') {
                this.setState((state) => {
                    return {index: state.index-1};
                });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleScrolling);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.hotelInfo && this.props.hotelInfo) {
            document.title = this.props.hotelInfo.title;
        }
    }

    updateDimensions = () => {
        this.setState((state) => {
            const patch = {
                width: window.innerWidth,
                height: window.innerHeight
            };
            if (patch.width > 640) {
                patch.isMenuOpen = true;
            } else if (!state.width || state.width > 640) {
                patch.isMenuOpen = false;
            }
            return patch;
        });
    };

    handleScrolling = () => {
        this.setState({
            isTopVisible: window.scrollY > window.innerHeight
        });
    };

    onLangOpen = (e) => {
        e.preventDefault();
        this.setState(prevState => {
            if (prevState.isLangOPen) {
                document.removeEventListener('click', this.onLangOpen);
            } else {
                document.addEventListener('click', this.onLangOpen);
            }
            return {
                isLangOPen: !prevState.isLangOPen
            };
        });
    };

    onHotelOpen = (e) => {
        e.preventDefault();
        this.setState(prevState => {
            if (prevState.isHotelOPen) {
                document.removeEventListener('click', this.onHotelOpen);
            } else {
                document.addEventListener('click', this.onHotelOpen);
            }
            return {
                isHotelOPen: !prevState.isHotelOPen
            };
        });
    };

    onLanguageSelect = (lang) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lang).then(() => {
            this.setState({lang});
            setCookie('django_language', lang);
            this.props.onLanguageSelect(lang);
        });
    };

    onHotelSelect = (hotel) => {
        const _hotel = hotel || this.state._hotel;
        this.setState({_hotel});
        this.props.setHotel(_hotel);
    };

    onAuthToggle = (e) => {
        this.setState(prevState => {
            if (prevState.isAuthOpen) {
                document.removeEventListener('click', this.onAuthToggle);
            } else {
                document.addEventListener('click', this.onAuthToggle);
            }
            return {
                isAuthOpen: !prevState.isAuthOpen
            };
        });
    };

    toggleMenu = () => {
        this.setState((state) => {
            return {isMenuOpen: !state.isMenuOpen};
        });
    };

    scrollToTop = () => {
        this.container.current.scrollIntoView();
    };

    renderHotels() {
        const {t} = this.props;
        const {isHotelOPen} = this.state;
        const label = t('Our hotels');
        const faClasses = ['fas', 'chevron-down'];

        return (
          <div className="app-header-lang" onClick={this.onHotelOpen}>
            <span className={block('lang', {open: isHotelOPen})}>{label} <FontAwesomeIcon icon={faClasses}/></span>
            {isHotelOPen && (
              <div className="app-header-lang-wrap">
                {hotels.map(hotel => {
                    return <div key={hotel} className="app-header-language"
                                onClick={this.onHotelSelect.bind(this, hotel)}>
                        {hotel}
                    </div>
                })}
              </div>
            )}
        </div>
      );
    }

    renderLanguages() {
        const {t} = this.props;
        const {isLangOPen, lang} = this.state;
        const language = languages.find((l) => l.code === lang);
        const label = isLangOPen ? t('Language') : language.title;
        const faClasses = ['fas', 'chevron-down'];

        return (
            <div className="app-header-lang" onClick={this.onLangOpen}>
                <span className={block('lang', {open: isLangOPen})}>
                    <img alt={language.code} src={language.flag} /> {label} <FontAwesomeIcon icon={faClasses}/></span>

                {isLangOPen && (
                  <div className="app-header-lang-wrap">
                    {
                        languages.map(lang => {
                            return <div key={lang.code} className={'app-header-language'}
                                        onClick={this.onLanguageSelect.bind(this, lang.code)}>
                                <img alt={lang.code} src={lang.flag} /> {lang.title}
                            </div>
                        })
                    }
                  </div>
                )}
            </div>
        );
    }

    renderUser() {
        const {t} = this.props;
        const {user} = this.props;
        const {isAuthOpen} = this.state;
        const label = user ? user.username : t('Login');
        const faClasses = ['fas', 'chevron-down'];

        return (
          <div className="app-header-login" onClick={this.onAuthToggle}>
            <span className="cursor login"><FontAwesomeIcon icon={['fas', 'user']}/> {label} <FontAwesomeIcon icon={faClasses}/></span>
            <div className="app-header-lang-wrap"
                 style={{
                     display: isAuthOpen ? "block" : "none"
                 }}
            >
                {user ? (
                  <Fragment>
                      <div className={'app-header-language'}>
                        <Link to={'/account'}>{t('Profile')}</Link>
                      </div>
                      <div className={'app-header-language'}>
                        <a href="/api/users/logout">{t('Logout')}</a>
                      </div>
                  </Fragment>
                ) : (
                  <Fragment>
                      <div className={'app-header-language'}>
                          <Link to="/register">{t('Create')}</Link>
                      </div>
                      <div className={'app-header-language'}>
                        <Link to="/login">{t('Already have an account')}</Link>
                      </div>
                  </Fragment>
                )}
            </div>
          </div>
        );
    }

    onOpenModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return this.callback.current && this.callback.current.open();
    };

    onMenuClick = (e) => {
        const width = window.innerWidth;
        const {isMenuOpen} = this.state;
        if (isMenuOpen && width < 640) {
            this.setState({isMenuOpen: false});
        }
    };

    render() {
        const {t} = this.props;
        const {hotelInfo, history, content} = this.props;
        const {isMenuOpen, isTopVisible} = this.state;

        const headerStyle = {};
        if (content && content.visual) {
            headerStyle['backgroundImage'] = `url(${content.visual})`;
        } else if (hotelInfo && hotelInfo.src) {
            headerStyle['backgroundImage'] = `url(${hotelInfo.src})`;
        }

        return (
            <header ref={this.container} id={'header'} className="app-header" style={headerStyle}>
                <Helmet>
                    {hotelInfo && (
                      <title>{hotelInfo.title}</title>)}
                    {content && content.meta_description && (
                      <meta name="description" content={content.meta_description}/>)}
                    {content && content.meta_keywords && (
                      <meta name="keywords" content={content.meta_keywords}/>)}
                </Helmet>
                {isTopVisible && (
                  <div className="top" onClick={this.scrollToTop}>
                    <FontAwesomeIcon icon={['fas', 'chevron-circle-up']}/>
                  </div>
                )}
                {this.state.index > 0 && (
                  <div className={block('back')} onClick={history.goBack}>
                    <FontAwesomeIcon icon={['fas', 'chevron-left']}/>
                  </div>
                )}
                <div className={'app-header-top'}>
                    {hotelInfo && (<div className="app-header-title">
                        <h1>{hotelInfo.title}</h1>
                        <div className="app-header-title-text">
                            <span className="app-header-line-left"/>
                            <span className="app-header-line-text">{hotelInfo.location}</span>
                            <span className="app-header-line-right"/>
                        </div>
                    </div>)}
                    <div className={'app-header-right'}>
                      <div className={'app-block-row app-header-login-wrapper'}>
                          <div className={block('toggle', {open: isMenuOpen})}
                               onClick={this.toggleMenu}>
                            <div className={block('toggle-stick')} />
                            <div className={block('toggle-stick')} />
                            <div className={block('toggle-stick')} />
                          </div>
                          <div className={'app-block-column app-header-login-container'}>
                              <div className={'app-header-login'}>
                                <div className={block('callback')} onClick={this.onOpenModal}>
                                    {t('Contact us')} <FontAwesomeIcon icon={['fas', 'phone']} />
                                </div>
                              </div>
                            {this.renderLanguages()}
                          </div>
                      </div>
                    </div>

                </div>

                <div className="app-header-menu" id={'main-menu'}>
                    {isMenuOpen && (
                      <div className="app-header-menu-container" onClick={this.onMenuClick}>
                        <ul className="app-header-menu-items">
                            <li><span className="cursor"><Link to={'/'}>{t('About')}</Link></span></li>
                            {this.props.pages.map((page) => {
                                return (
                                  <li key={page.code}>
                                      <span className="cursor">
                                          <Link to={`/${page.code}`}>{page.title}</Link>
                                      </span>
                                  </li>
                                );
                            })}
                        </ul>
                      </div>
                    )}
                    {hotelInfo && (
                      <div className="app-header-scores-container">
                      {hotelInfo.booking && (
                        <div className={'app-header-score-container'}>
                          <div className="app-header-booking-container">
                            <img width="234" alt="Booking" src={hotelInfo.booking}/>
                          </div>
                        </div>
                      )}
                      {hotelInfo.tripadvisor && (
                        <div className={'app-header-score-container'}>
                          <div className="app-header-tripadvisor-container">
                            <img width="234" alt="TripAdvisor" src={hotelInfo.tripadvisor}/>
                          </div>
                        </div>
                      )}
                      </div>
                    )}
                </div>
                <div className="app-header-form">
                  <Booking />
                </div>
                <Callback forwardRef={this.callback} />
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hotelInfo: state.hotelInfo.hotelInfo,
        user: state.user.user,
        content: state.page.content,
        pages: state.cache.pages
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setHotel: (hotel) => {
            dispatch(setHotel(hotel))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));