import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import bem from 'b_';

import './style.scss';

import BreadCrumbs from 'components/breadcrumbs';
import {getRooms} from "../../reducers/rooms"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TitleText from 'components/title-text';
import Slider from "../slider/index"

const block = bem.with('app-content-page');

class Hours extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryId: null,
            names: null,
            rooms: null
        };
        this.rooms = React.createRef();
        this.names = React.createRef();
    }

    componentDidMount() {
        this.props.getRooms();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.rooms.objects && this.props.rooms.objects.length) {
            if (this.rooms.current && !this.state.rooms) {
                this.setState({
                    names: this.names.current,
                    rooms: this.rooms.current
                });
            }
        }
        if (this.props.language !== prevProps.language) {
            this.props.getRooms();
        }
    }

    onGalleryToggle = (i) => {
        this.setState({
            categoryId: i
        });
    };

    render() {
        const {content, rooms, t} = this.props;
        const id = parseInt(this.props.match.params.id);
        const {categoryId} = this.state;

        if (!rooms.objects) {
            return (
              <div className="route-page">Loading...</div>
            );
        }
        const category = rooms.objects.filter(cat => cat.id === categoryId)[0];

        if (id) {
            const idx = rooms.objects.findIndex(r => r.id === id);
            if (idx !== -1) {
                for (let i = 0; i < idx; i++) {
                    rooms.objects.push(rooms.objects.shift());
                }
            }
        }

        const nameSliderSettings = {
            arrows: false,
            slidesToShow: 3,
            asNavFor: this.rooms.current,
            focusOnSelect: true,
            centerMode: true,
            className: 'names-slider'
        };

        const roomSliderSettings = {
            asNavFor: this.names.current,
            adaptiveHeight: true,
        };

        return (
            <section className="rooms route-page">
                {category && (
                  <section className="gallery">
                    <Slider>
                        {category.gallery.map((gal) => {
                            return  (
                              <Slider key={gal.src}>
                                <img alt={category.title} src={gal.src} width="300px"/>
                              </Slider>
                            );
                        })}
                    </Slider>
                    <div className="close" onClick={this.onGalleryToggle}>
                        <FontAwesomeIcon icon={['fas', 'times']}/>
                    </div>
                  </section>
                )}
                <BreadCrumbs title={t('Rooms')}/>
                {content && (
                  <section className={block()}>
                    <div className={block('container')}>
                      <div  className={block('text')}>
                        {!content.src && (
                          <div className="heading">
                            <h2>{content.title}</h2>
                            {content.subtitle && (<TitleText>{content.subtitle}</TitleText>)}
                          </div>
                        )}
                        <div className={block('description')}
                             dangerouslySetInnerHTML={{__html: content.description}} />
                      </div>
                      {content.src && (
                          <div className={block('image')}>
                              <img src={content.src} title={content.title} alt={content.title} />
                          </div>
                      )}
                    </div>
                  </section>
                )}
                <section className="categories-wrapper">
                    <Slider sliderSettings={nameSliderSettings} setRef={this.names}>
                        {rooms.objects.map((category) => {
                            return (
                              <div className={'names-slide'} key={category.id}>
                                  <span>{category.title}</span>
                              </div>
                            );
                        })}
                    </Slider>
                    <Slider sliderSettings={roomSliderSettings} setRef={this.rooms}>
                        {rooms.objects.map((category) => {
                            return <section className="special-cards" key={category.id}>
                                <div className="card">
                                    <div className={'card-img'}>
                                        <img alt={category.title} src={category.src} width="300px" />
                                    </div>
                                    <div className="info-wrapper">
                                        <div className="title">{category.title}</div>
                                        <div className="info" dangerouslySetInnerHTML={{__html: category.description}} />
                                        {category.hourPrice && (
                                          <div className={block('value')}>
                                            <div>{t('Price')}</div>
                                            <span>RUB <br/> {category.hourPrice}</span>
                                          </div>)}
                                        <div className={'app-content-slider__block-actions'}>
                                            {category.gallery.length > 0 && (
                                              <button className="read-more"
                                                onClick={this.onGalleryToggle.bind(this, category.id)}>
                                                  {t('Photo gallery')}
                                              </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={'card-details'}
                                     dangerouslySetInnerHTML={{__html: category.details}} />
                            </section>
                        })}
                    </Slider>
                </section>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rooms: state.rooms.rooms,
        content: state.page.content,
        hotel: state.hotelInfo.hotel,
        language: state.cache.language
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRooms: () => {
            dispatch(getRooms())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Hours));