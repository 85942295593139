import { action } from "lib";
import { actionTypes, BASE_URL } from "store";

export const toggleAuthModal = () => {
  return { type: actionTypes.TOGGLE_AUTH };
};

export const setHotelInfo = payload => {
  return { type: actionTypes.SET_HOTEL_INFO, payload };
};

export const setHotel = hotel => {
  return { type: actionTypes.SET_HOTEL, hotel };
};

export const setRooms = rooms => {
  return { type: actionTypes.SET_ROOMS, rooms };
};

export const setRestaurantInfo = restaurant => {
  return { type: actionTypes.SET_RESTAURANT_INFO, restaurant };
};

export const setConferenceRoomInfo = confRoom => {
  return { type: actionTypes.SET_CONFERENCE_ROOM_INFO, confRoom };
};

export const setOffers = offers => {
  return { type: actionTypes.SET_OFFERS, offers };
};

export const setBanners = banners => {
  return { type: actionTypes.SET_BANNERS, banners };
};

export const setPageContent = content => {
  return action(actionTypes.SET_PAGE_CONTENT, { content });
};

export const setMenuPages = payload => {
  return action(actionTypes.SET_MENU_PAGES, payload);
};

export const setUser = payload => {
  return action(actionTypes.SET_USER, payload);
};

export const setError = payload => {
  return action(actionTypes.SET_ERROR, payload);
};

export const setCountries = payload => {
  return action(actionTypes.SET_COUNTRIES, payload);
};

export const setCities = payload => {
  return action(actionTypes.SET_CITIES, payload);
};

export const setGalleries = payload => {
  return action(actionTypes.SET_GALLERIES, payload);
};

export const setLanguage = language => {
  return action(actionTypes.SET_LANGUAGE, { language });
};

export const setPageScripts = (page, scripts) => {
  return action(actionTypes.SET_PAGE_SCRIPTS, { page, scripts });
};

export const getPageContent = page => {
  if (!page || page === "/") {
    page = "main";
  } else if (page.startsWith("/")) {
    page = page.substring(1);
  }
  return dispatch => {
    fetch(`${BASE_URL}/api/pages/${page}`)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(data => {
        dispatch(setPageContent(data));
      })
      .catch(err => console.log(err));
  };
};

export const getMenuPages = () => {
  return dispatch => {
    fetch(`${BASE_URL}/api/pages?menu`)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(payload => {
        dispatch(setMenuPages(payload));
      })
      .catch(err => console.log(err));
  };
};

export const getUser = () => {
  return dispatch => {
    fetch(`${BASE_URL}/api/users/me`)
      .catch(err => console.log(err))
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject(res.json());
        }
      })
      .then(user => {
        return { user };
      })
      .catch(data => {
        return data;
      })
      .then(payload => {
        dispatch(setUser(payload));
      });
  };
};

export const getToken = () => {
  return dispatch => {
    fetch(`${BASE_URL}/api/users/token`)
      .catch(err => console.log(err))
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(payload => {
        dispatch(setUser(payload));
      });
  };
};

export const registerUser = formData => {
  return dispatch => {
    return fetch(`${BASE_URL}/api/users/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest"
      },
      body: formData
    })
      .catch(err => console.log(err))
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject(res.json());
        }
      })
      .then(user => {
        return { user };
      })
      .catch(data => {
        return data;
      })
      .then(payload => {
        dispatch(setUser(payload));
      });
  };
};

export const loginUser = formData => {
  return dispatch => {
    return fetch(`${BASE_URL}/api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest"
      },
      body: formData
    })
      .catch(err => console.log(err))
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject(res.json());
        }
      })
      .then(user => {
        return { user };
      })
      .catch(data => {
        return data;
      })
      .then(payload => {
        dispatch(setUser(payload));
      });
  };
};

export const callUser = formData => {
  return dispatch => {
    return fetch(`${BASE_URL}/api/users/callback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": "XMLHttpRequest"
      },
      body: formData
    })
      .catch(err => console.log(err))
      .then(res => {
        return res.json();
      });
  };
};

export const getCountries = name => {
  return dispatch => {
    return fetch(`${BASE_URL}/api/countries?name=${name}`)
      .catch(err => console.log(err))
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(payload => {
        dispatch(setCountries(payload));
      });
  };
};

export const getCities = (name, country) => {
  return dispatch => {
    return fetch(`${BASE_URL}/api/cities?name=${name}&country=${country}`)
      .catch(err => console.log(err))
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(payload => {
        dispatch(setCities(payload));
      });
  };
};

export const getPageScripts = page => {
  if (!page || page === "/") {
    page = "main";
  } else if (page.startsWith("/")) {
    page = page.substring(1);
  }
  return dispatch => {
    fetch(`${BASE_URL}/api/scripts?page=${page}`)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(data => {
        dispatch(setPageScripts(page, data.objects));
      })
      .catch(err => console.log(err));
  };
};
