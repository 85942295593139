import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import bem from 'b_';
import {withTranslation} from 'react-i18next';

import BreadCrumbs from 'components/breadcrumbs';
import {getUser} from '../../actions';
import {Link} from "react-router-dom";

import './style.scss';

const block = bem.with('app-account');

class Account extends Component {
    render() {
        const {user, t} = this.props;

        return (
          <section className={'route-page'}>
            <BreadCrumbs title={t('Profile')} />
            <div className={'heading'}>
                <h2>{t('Profile')}</h2>
                <div className={'app-heading-title-text'}>
                    <span className={'app-heading-line-left'} />
                    <span className={'app-heading-line-text'}>{t('Access booking history')}</span>
                    <span className={'app-heading-line-right'} />
                </div>
            </div>

            <section className={block()}>
                {user ? (
                  <Fragment>
                    <div>
                      <p>{user.firstName} {user.lastName}</p>
                      <p>{user.accommodation && user.accommodation.Status}</p>
                    </div>
                  </Fragment>
                ) : (
                  <div className={'app-content-slider__block-actions app-content-slider__block-actions_distribute'}>
                    <Link className={'action'} to={'/login'}>{t('Login to account')}</Link>
                    <Link className={'action'} to={'/register'}>{t('Create new account')}</Link>
                  </div>
                )}
            </section>
          </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    };
};

const mapDispatchToProps = {
    getUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Account));