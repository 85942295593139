import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withTranslation} from 'react-i18next';

import './style.scss';

import Map from 'components/map';
import BreadCrumbs from 'components/breadcrumbs';
import {stripPhone} from '../../lib';


class Location extends Component {
    static defaultProps = {
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Fdc8qiUCgD9IJz2wMaPMSPR6AO4idwk&v=3.exp&libraries=geometry,drawing,places`
    };

    render() {
        const {hotelInfo, t} = this.props;
        if (!hotelInfo) {
            return (
              <div className="route-page">Loading...</div>
            );
        }
        return (
            <section className="hotel-location route-page">
                <BreadCrumbs title={t('Contacts')} />
                <div className="hotel-location-map-container">
                    <Map
                      googleMapURL={this.props.googleMapURL}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      mapLocation={hotelInfo.mapLocation}
                    />
                </div>
                <div className="container about-section">
                    <div className="editor-content" dangerouslySetInnerHTML={{__html: hotelInfo.locationInfo}} />
                    <div className="contact-block">
                        <div className="title">{t('Address and contacts')}</div>
                        <div className="contact-detail">
                            <table className="address">
                                <tbody>
                                <tr>
                                    <td><strong>{t('Address')}</strong></td>
                                    <td>{hotelInfo.address}</td>
                                </tr>
                                {hotelInfo.phone && (
                                  <tr>
                                    <td><strong>{t('Phone')}</strong> <FontAwesomeIcon icon={['fa', 'phone']}/></td>
                                    <td>
                                        <a target={'_blank'}
                                           href={`tel:${hotelInfo.phone}`}>{hotelInfo.phone}</a>
                                    </td>
                                  </tr>
                                )}
                                {hotelInfo.whatsup && (
                                  <tr>
                                    <td><strong>WhatsApp</strong> <FontAwesomeIcon icon={['fab', 'whatsapp']}
                                                             style={{
                                                                 color: '#25D366',
                                                                 fontSize: '15px'
                                                             }}/></td>
                                    <td>
                                        <a target={'_blank'}
                                           href={`https://wa.me/${stripPhone(hotelInfo.whatsup)}`}>{hotelInfo.whatsup}</a>
                                    </td>
                                  </tr>
                                )}
                                {hotelInfo.email && (
                                  <tr>
                                    <td><strong>E-mail</strong></td>
                                    <td>
                                        <a target={'_blank'}
                                           href={`mailto:${hotelInfo.email}`}>{hotelInfo.email}</a>
                                    </td>
                                  </tr>
                                )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="contact-block">
                        <div className="title">{t('Accommodation information')}</div>
                        <div className="about-tab-item">
                            <div id="accordion_arrival-information" className="panel-group">
                                <div className="panel panel-default faq-item">
                                    <div className="panel-collapse">
                                        <div className="panel-body">
                                            <div className="answer">
                                                <p>{t('Checkin')} - {hotelInfo.checkin}<br/>
                                                    {t('Checkout')} - {hotelInfo.checkout}</p>
                                                <div dangerouslySetInnerHTML={{__html: hotelInfo.checkinNote}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-description" />
                    </div>
                </div>
                <div className="about-section contact-section section-block">
                    <div className="container">

                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.hotelInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Location));
