import update from 'react-addons-update';

import {actionTypes} from '../store';
// import {createReducer} from '../lib/index';

const initState = {
    content: undefined
};

// export default createReducer(initState, {
//   [actionTypes.SET_PAGE_CONTENT]: (state, {content}) => {
//     return update(state, {
//       content: {$set: content}
//     });
//   }
// });

const page = (state = initState, action) => {
  if (action.type === actionTypes.SET_PAGE_CONTENT) {
    const {content} = action.payload;
    return update(state, {
      content: {$set: content}
    });
  } else {
    return state;
  }
};

export default page;