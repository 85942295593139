import React from 'react';
import bem from 'b_';

import './style.scss';


const block = bem.with('app-modal');

export default class Modal extends React.Component {
  static defaultProps = {
    closeOnClick: true,
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      open: false,
    };
  }

  open = () => {
    this.setState({open: true});
  };

  close = () => {
    this.setState({open: false});
  };

  onClick = (event) => {
    if (this.container.current !== event.target) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (this.props.closeOnClick) {
      this.close();
    }
  };

  render() {
    if (!this.state.open) {
      return null;
    }
    return (
      <div className={block('container')} ref={this.container}
           onClick={this.onClick}>
        {this.props.children}
      </div>
    );
  }
}