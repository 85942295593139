import React from 'react';


export default class SubTitle extends React.PureComponent {
  render() {
    return (
      <div className={'sub-child-title'}>
        {this.props.children}
      </div>
    );
  }
}