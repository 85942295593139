import React from 'react';
import bem from 'b_';


const block = bem.with('app-heading');

export default class TitleText extends React.PureComponent {
  render() {
    return (
      <div className={`${block()} app-heading-title-text`}>
        <span className={'app-heading-line-left'}/>
        <span className={'app-heading-line-text'}>{this.props.children}</span>
        <span className={'app-heading-line-right'}/>
      </div>
    );
  }
}