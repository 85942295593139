import React from 'react';

import './style.scss';


export default class Booking extends React.PureComponent {
  constructor(props) {
    super(props);
    this.script = document.createElement('script');
    this.script.src = '/js/travelline.js';
    this.script.async = true;
    this.script.type = 'text/javascript';
  }

  componentDidMount() {
    document.body.appendChild(this.script);
  }

  render() {
    return (
      <div className={'hotel-booking'} id={'main-booking'}>
        <div id={'tl-search-form'} />
      </div>
    );
  }
}