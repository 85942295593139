import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import bem from 'b_';
import {withTranslation} from 'react-i18next';

import {getUser, callUser, getToken} from '../../actions';
import Modal from 'components/modal';
import SubTitle from 'components/subtitle';
import TitleText from 'components/title-text';

import './style.scss';


const block = bem.with('app-form');

class Callback extends Component {
    static defaultProps = {
        token: '',
        forwardRef: React.createRef()
    };

    constructor(props) {
        super(props);
        this.state = {
          name: '',
          phone: '+7 ',
          email: '',
          csrfmiddlewaretoken: '',
          comment: '',
          errors: {},
          confirm: false,
        };
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const {token, user} = this.props;
        if (token !== this.state.csrfmiddlewaretoken) {
          this.setState({csrfmiddlewaretoken: token});
        }
        if (!this.state.csrfmiddlewaretoken && !token && user) {
          this.props.getToken();
        }
        if (prevProps.user !== user) {
          if (user) {
            const patch = {};
            if (!this.state.name && user.firstName) {
              patch.name = user.firstName;
            }
            if (!this.state.email && user.email) {
              patch.email = user.email;
            }
            if (!this.state.phone && user.phone) {
              patch.phone = user.phone;
            }
            this.setState(patch);
          }
        }
    }


    renderErrors(name) {
      const {errors} = this.state;

      if (errors.hasOwnProperty(name)) {
        return errors[name].map((err) => {
            return (
              <span className={block('error')}>{err}</span>
            );
        });
      }
    }

    filterPhoneKeys = (event) => {
      const alphabet = '+-()0123456789 ';
      if (alphabet.indexOf(event.key) === -1) {
          event.preventDefault();
          event.stopPropagation();
      }
    };

    onSubmit = (event) => {
      event.preventDefault();
      const formData = new URLSearchParams();
      Object.entries(this.state).forEach(([key, value]) => {
          formData.append(key, value);
      });
      this.props.callUser(formData).then((data) => {
        if (data.result) {
          const {current} = this.props.forwardRef;
          current && current.close && current.close();
        } else {
          this.setState(data);
        }
      });
    };

    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    };

    onClose = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const {current} = this.props.forwardRef;
      return current && current.close && current.close();
    };

    render() {
      const {hotel, user, t} = this.props;
      return (
          <Modal ref={this.props.forwardRef}>
            <div className={block('wrapper')}>
              <div className={'close'} onClick={this.onClose}>
                <FontAwesomeIcon icon={['fas', 'times']}/>
              </div>
              <div className={'heading'}>
                <h2>{t('Contact us')}</h2>
                {hotel && hotel.phone && (
                  <TitleText text={hotel.phone}>
                    <a href={`tel:${hotel.phone}`}>{hotel.phone}</a>
                  </TitleText>
                )}
                {hotel && hotel.email && (
                  <SubTitle>
                    <a href={`mailto:${hotel.email}`}>{hotel.email}</a>
                  </SubTitle>
                )}
              </div>
              <div className={'app-content-page'}>
                <div className={'app-content-page__description'}>
                  <p>{t('Booking and information support.')}</p>
                </div>
              </div>
              <div className={block('container')}>
                  <form className={block()} action={'/api/users/callback'} method={'POST'} onSubmit={this.onSubmit}>
                    {this.renderErrors('__all__')}
                    <input type={'hidden'} name={'csrfmiddlewaretoken'} value={this.state.csrfmiddlewaretoken} />
                    {(!user || !user.firstName) && (
                      <div className={block('group')}>
                          <label className={block('label')} htmlFor="name">{t('Name')}</label>
                          <div className={block('input')}>
                              <input id="name" name="name" required type="text"
                                     value={this.state.name} onChange={this.handleInputChange}/>
                              {this.renderErrors('name')}
                          </div>
                      </div>
                    )}
                    {(!user || !user.phone) && (
                      <div className={block('group')}>
                          <label className={block('label')} htmlFor="phone">{t('Phone')}</label>
                          <div className={block('input')}>
                              <input id="phone" name="phone" type="tel"
                                     required={true}
                                     value={this.state.phone} onChange={this.handleInputChange}
                                     onKeyPress={this.filterPhoneKeys}/>
                              {this.renderErrors('phone')}
                          </div>
                      </div>
                    )}
                    {(!user || !user.email) && (
                      <div className={block('group')}>
                          <label className={block('label')} htmlFor="email">E-mail</label>
                          <div className={block('input')}>
                              <input id="email" name="email" required type="email"
                                     value={this.state.email} onChange={this.handleInputChange} />
                              {this.renderErrors('email')}
                          </div>
                      </div>
                    )}
                    <div className={block('group')}>
                        <label className={block('label')} htmlFor={'subject'}>{t('Subject')}</label>
                        <div className={block('input')}>
                            <select name={'subject'} id='subject'
                                    value={this.state.language} onChange={this.handleInputChange} >
                                <option>{t('Choose subject')}</option>
                                <option value={t('Call me')}>{t('Call me')}</option>
                                <option value={t('Booking')}>{t('Booking')}</option>
                            </select>
                            {this.renderErrors('subject')}
                        </div>
                    </div>
                    <div className={block('group')}>
                        <label className={block('label')} htmlFor={'time'}>{t('Call interval')}</label>
                        <div className={block('input')}>
                            <select name={'time'} id={'time'}
                                    value={this.state.language} onChange={this.handleInputChange} >
                              <option>{t('Choose interval')}</option>
                              <option value={t('now')}>{t('now')}</option>
                              <option value={'09:00-12:00'}>09:00-12:00</option>
                              <option value={'12:00-15:00'}>12:00-15:00</option>
                              <option value={'15:00-18:00'}>15:00-18:00</option>
                              <option value={'18:00-20:00'}>18:00-20:00</option>
                            </select>
                            {this.renderErrors('time')}
                        </div>
                    </div>
                    <div className={block('group')}>
                        <label className={block('label')} htmlFor={'time'}>{t('Comment')}</label>
                        <div className={block('input')}>
                            <textarea name={'comment'} id={'comment'}
                                    value={this.state.comment} onChange={this.handleInputChange} >
                            </textarea>
                            {this.renderErrors('comment')}
                        </div>
                    </div>
                    <div className={block('group')}>
                        <div className={block('input', {type: 'checkbox'})}>
                            <input id={'call_confirm'} name={'confirm'} required type="checkbox"
                                   checked={this.state.confirm} onChange={this.handleInputChange} />
                            <label className={block('label')} htmlFor={'call_confirm'}>
                              {t('I agree with data processing policy')}</label>
                        </div>
                    </div>
                    <div className={block('group', {actions: true})}>
                        <button className={'action'}>{t('Send')}</button>
                    </div>
                  </form>
              </div>
            </div>
          </Modal>
      );
    }
}

const mapStateToProps = (state) => {
    return {
      hotel: state.hotelInfo.hotelInfo,
      user: state.user.user,
      token: state.user.token
    };
};

const mapDispatchToProps = {
  getUser,
  getToken,
  callUser
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Callback));
