import i18n from "i18next";
import {initReactI18next} from "react-i18next";


import resources from './lang/common.json';
import {getCookie} from './lib';

i18n
  .use(initReactI18next)
  .init({
    resources,
    whitelist: ['ru', 'en'],
    fallbackLng: 'ru',
    lng: getCookie('django_language') || 'ru',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
