export const action = (type, payload) => {
  return {type, payload};
};

export const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    const handler = handlers[action.type];
    if (handler) {
      return handler(state, action);
    }
    return state;
  };
};

export const stripPhone = (phone) => {
  return phone.replace(/[^\d]/g, '');
};

export const setCookie = (name, value) => {
  document.cookie = `${decodeURIComponent(name)}=${decodeURIComponent(value)}`;
};

export const getCookie = (name) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const expr = new RegExp(`\\b${decodeURIComponent(name)}=([^;]*)`);
  const match = decodedCookie.match(expr);
  return match && match[1];
};