import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation} from 'react-i18next';

import './style.scss';
import {connect} from "react-redux";
import {stripPhone} from "../../lib";


class Footer extends Component {
    static defaultProps = {
        hotelInfo: {},
        pages: []
    };

    render() {
        const {hotelInfo, t} = this.props;

        return (
            <footer className="app-footer">
                <div className="app-footer-menu">
                    <div className="app-footer-menu-container">
                        <h3>{t('Contact us')}</h3>
                        <div className={'app-footer__contacts'}>
                            {hotelInfo.phone && (
                              <a className={'app-footer__phone'}
                                 target={'_blank'}
                                 href={`tel:${hotelInfo.phone}`}>{hotelInfo.phone}</a>
                            )}
                            {hotelInfo.email && (
                              <a className={'app-footer__email'}
                                 target={'_blank'}
                                 href={`mailto:${hotelInfo.email}`}>{hotelInfo.email}</a>
                            )}
                        </div>
                        <div className={'app-footer__social'}>
                            <a target={'_blank'} href={hotelInfo.facebook || 'https://www.facebook.com/cityhotelgroup'}>
                                <FontAwesomeIcon icon={['fab', 'facebook-square']}/>
                            </a>
                            <a target={'_blank'} href={hotelInfo.instagram || 'https://www.instagram.com/cityhotelgroup/'}>
                                <FontAwesomeIcon icon={['fab', 'instagram']}/>
                            </a>
                            {hotelInfo.whatsup && (
                              <a target={'_blank'}
                                 href={`https://wa.me/${stripPhone(hotelInfo.whatsup)}`}>
                                <FontAwesomeIcon icon={['fab', 'whatsapp']}/>
                              </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className="app-footer-menu">
                    <div className="app-footer-menu-container">
                        <h3>{t('Main menu')}</h3>
                        <ul className="app-footer-menu-items">
                            <li><FontAwesomeIcon icon={['fa', 'chevron-right']}/>
                                <Link to={'/'}>{t('About')}</Link></li>
                            {this.props.pages.map((page) => {
                                return (
                                    <li key={page.code}>
                                        <FontAwesomeIcon icon={['fa', 'chevron-right']}/>
                                        <Link to={`/${page.code}`}>{page.title}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="app-footer-menu">
                    <div className="app-footer-menu-container">
                        <h3>{t('Additional information')}</h3>
                        <ul className="app-footer-menu-items">
                            <li><FontAwesomeIcon icon={['fa', 'chevron-right']}/>
                                <Link to={'/accommodations'}>{t('Accommodations')}</Link></li>
                            <li><FontAwesomeIcon icon={['fa', 'chevron-right']}/>
                                <Link to={'/privacy-policy'}>{t('Privacy policy')}</Link></li>
                            <li><FontAwesomeIcon icon={['fa', 'chevron-right']}/>
                                <Link to={'/loyalty'}>{t('Loyalty program')}</Link></li>
                        </ul>
                    </div>
                </div>
                {hotelInfo.address && (
                  <div className={'app-footer__address'}>
                    <span>{hotelInfo.address}</span>
                  </div>
                )}
            </footer>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.hotelInfo,
        pages: state.cache.pages
    };
};


export default connect(mapStateToProps, {})(withTranslation()(Footer));
