import React from 'react';
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import bem from 'b_';
import {withTranslation} from 'react-i18next';

import {getCookie, setCookie} from '../../lib';
import {getBanners} from "../../reducers/offers";

import './style.scss';


const block = bem.with('app-promo');

const PROMO_CODE = 'PREMIUM';
const BANNER_ID = 2;

class Promo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: getCookie('TL_PROMO'),
      render: false
    };
  }

  componentDidMount() {
    this.props.getBanners();
    if (this.shouldRender()) {
      setTimeout(() => {
        this.setState({render: true});
      }, 10000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {pathname} = prevProps.location;
    if (this.shouldRender() !== this.shouldRender(pathname)) {
      if (this.shouldRender()) {
        setTimeout(() => {
          this.setState({render: true});
        }, 10000);
      } else {
        this.setState({render: false});
      }
    }
  }

  shouldRender(pathname = this.props.location.pathname) {
    return ['/booking', '/rooms'].findIndex((prefix) => {
      return pathname.startsWith(prefix);
    }) !== -1;
  }

  onClick = (e) => {
    setCookie('TL_PROMO', PROMO_CODE);
    this.setState({promoCode: PROMO_CODE}, () => {
      if (this.props.location.pathname.startsWith('/booking')) {
        window.location.reload();
      }
    });
  };

  onApply = (e) => {
    this.onClick(e);
    window.location.reload();
  };

  render() {
    const {banners, t} = this.props;
    const banner = (banners && banners.objects && banners.objects.length > 0)
      ? banners.objects.find((b) => b.id === BANNER_ID)
      : undefined;
    if (!banner || this.state.promoCode || !this.state.render) {
      return null;
    }
    return (
      <div className={block('container')} onClick={this.onClick}>
        <div className={block('body')}>
          <div className={block('close')} onClick={this.onClick}>
            <FontAwesomeIcon icon={['fas', 'times']}/>
          </div>
          {banner.src && (
            <div className={block('img')}>
              <img src={banner.src} alt={banner.title}/>
            </div>
          )}
          <div className={block('content')} dangerouslySetInnerHTML={{__html: banner.description}} />
          <div className={block('block-actions')}>
            {banner.page && (
              <Link to={`/${banner.page.code}`} className={'read-more'}>{t('Details')}</Link>
            )}
            {this.props.location.pathname.startsWith('/booking') ? (
              <a href={this.props.location.pathname}
                 onClick={this.onApply} className={'action'}>{t('Apply')}</a>
              ) : (
              <Link to={`/booking?promo-code-plain=${PROMO_CODE}`}
                    className={'action'}>{t('Book')}</Link>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        banners: state.offers.banners
    };
};

const mapDispatchToProps = {
    getBanners
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Promo));