import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFacebookSquare, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faPhoneSquare, faMapMarked, faUser, faTimes, faAt} from '@fortawesome/free-solid-svg-icons';
import {faBed, faMugHot, faSnowflake, faShower, faPhone} from '@fortawesome/free-solid-svg-icons';
import {faChevronLeft, faChevronRight, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {faChevronCircleRight, faChevronCircleLeft, faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';
// import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import {registerLocale, setDefaultLocale} from 'react-datepicker';

import ru from 'date-fns/locale/ru';

import Header from './components/header';
import Footer from './components/footer';
import LiveChat from './components/livechat';
import Promo from './components/promo';
import Script from './components/script';
import {AppRouting} from "./routes"
import {dispatchHotelInfo} from 'reducers/hotel-info';
import {getPageContent, getUser, getMenuPages, setLanguage, getPageScripts} from './actions';

import './App.scss';

library.add(faUser);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faChevronCircleLeft);
library.add(faChevronCircleRight);
library.add(faChevronCircleUp);
library.add(faTimes);

library.add(faFacebookSquare);
library.add(faInstagram);
library.add(faWhatsapp);
library.add(faPhoneSquare);
library.add(faMapMarked);

library.add(faBed);
library.add(faMugHot);
library.add(faSnowflake);
library.add(faShower);
library.add(faPhone);
library.add(faAt);

registerLocale('ru', ru);
setDefaultLocale('ru');

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 'main'
        };
    }

    componentDidMount() {
        this.props.getMenuPages();
        this.props.dispatchHotelInfo();
        this.props.getUser();
        let page = this.props.location.pathname;
          if (!page || page === '/') {
              page = 'main';
          } else if (page.startsWith('/')) {
              page = page.substring(1);
          }
        this.props.getPageContent(page);
        this.props.getPageScripts(page);
        this.setState({page});
    }

    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
          let page = this.props.location.pathname;
          if (!page || page === '/') {
              page = 'main';
          } else if (page.startsWith('/')) {
              page = page.substring(1);
          }
          this.props.getPageContent(page);
          this.props.getPageScripts(page);
          this.setState({page});
        }
        const element = document.getElementById('header');
        if (element) {
          element.scrollIntoView();
        }
      }
    }

    onLanguageSelect = (language) => {
        this.props.setLanguage(language);
        this.props.getMenuPages();
        this.props.dispatchHotelInfo();
        this.props.getUser();
        this.props.getPageContent(this.props.location.pathname);
    };

    render() {
        const {scripts} = this.props;
        return (
            <div className="app">
                <Header history={this.props.history} onLanguageSelect={this.onLanguageSelect} />
                <AppRouting/>
                <Footer/>
                <LiveChat/>
                <Promo location={this.props.location} />
                {scripts && scripts.map((s) => {
                  return (<Script key={s.id} src={s.src} />);
                })}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
  return {
    scripts: state.cache.scripts
  };
};

const mapDispatchToProps = {
  dispatchHotelInfo,
  getUser,
  getPageContent,
  getMenuPages,
  setLanguage,
  getPageScripts
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
