import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { getOffers } from "../../reducers/offers";

import "./style.scss";

import BreadCrumbs from "components/breadcrumbs";

class SpecialOffers extends Component {
  componentDidMount() {
    this.props.getOffers();
  }

  render() {
    const { offers, t } = this.props;

    if (!offers.objects) {
      return <div className="route-page">Loading...</div>;
    }

    return (
      <section className="special-offers route-page">
        <BreadCrumbs title={t("Special offers")} />
        <section className={"special-cards-wrapper"}>
          <div className={"special-cards"}>
            {offers.objects.map(offer => {
              return (
                <div className={"card"} key={offer.id}>
                  <div className={"card-img"}>
                    <Link to={offer.link}>
                      <img alt={offer.title} src={offer.src} width="300px" />
                    </Link>
                  </div>
                  <div className="info-wrapper">
                    <div className={"card-content"}>
                      <div className={"card-text"}>
                        <div>
                          <div className="title">
                            <Link to={offer.link}>{offer.title}</Link>
                          </div>
                          <div
                            className="info"
                            dangerouslySetInnerHTML={{
                              __html: offer.description
                            }}
                          />
                        </div>
                        {offer.amount && (
                          <div className="value">
                            <div>{t("Price")}</div>
                            <span>
                              RUB <br /> {offer.amount}
                            </span>
                          </div>
                        )}
                      </div>
                      {offer.link && (
                        <div
                          className={
                            "app-content-slider__block-actions" +
                            " app-content-slider__block-actions_right"
                          }
                        >
                          <Link to={offer.link} className={"read-more"}>
                            {t("Details")}
                          </Link>
                        </div>
                      )}
                      {offer.validTo && (
                        <div className="text-valid">
                          {t("Valid till")} {offer.validTo}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    offers: state.offers.offers
  };
};

const mapDispatchToProps = {
  getOffers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SpecialOffers));
