import {createStore, applyMiddleware, combineReducers} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import hotelInfo from "./reducers/hotel-info";
import modals from "./reducers/modals";
import rooms from "./reducers/rooms";
import restaurant from "./reducers/restaurant";
import confRoom from "./reducers/conference-room";
import offers from "./reducers/offers";
import page from 'reducers/page';
import user from 'reducers/user';
import cache from 'reducers/cache';

// export const BASE_URL = `${window.location.protocol}//${window.location.host}`;
// export const BASE_URL = `http://127.0.0.1:8000`;
export const BASE_URL = '';

//ACTION TYPES
export const actionTypes = {
    TOGGLE_AUTH: "TOGGLE_AUTH",
    SET_HOTEL_INFO: "SET_HOTEL_INFO",
    SET_HOTEL: "SET_HOTEL",
    SET_ROOMS: "SET_ROOMS",
    SET_RESTAURANT_INFO: "SET_RESTAURANT_INFO",
    SET_CONFERENCE_ROOM_INFO: "SET_CONFERENCE_ROOM_INFO",
    SET_OFFERS: "SET_OFFERS",
    SET_BANNERS: "SET_BANNERS",
    SET_PAGE_CONTENT: 'SET_PAGE_CONTENT',
    SET_MENU_PAGES: 'SET_MENU_PAGES',
    SET_USER: 'SET_USER',
    SET_ERROR: 'SET_ERROR',
    SET_COUNTRIES: 'SET_COUNTRIES',
    SET_CITIES: 'SET_CITIES',
    SET_GALLERIES: 'SET_GALLERIES',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_PAGE_SCRIPTS: 'SET_PAGE_SCRIPTS',
};

const reducers = combineReducers({
    hotelInfo,
    modals,
    rooms,
    restaurant,
    confRoom,
    offers,
    page,
    user,
    cache
});

//store
export const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
);
