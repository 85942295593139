import {actionTypes, BASE_URL} from "../store";
import {setHotelInfo, setGalleries} from "../actions/index"


const initState = {
    hotelInfo: undefined,
    hotel: undefined,
    galleries: []
};


const hotelInfo = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_HOTEL_INFO:
            return {...state,  ...action.payload};
        case actionTypes.SET_HOTEL:
            return {...state,  hotel: action.hotel};
        case actionTypes.SET_GALLERIES:
            return {...state,  ...action.payload};
        default:
            return state
    }
};


export const dispatchHotelInfo = () => {
    return (dispatch) => {
        fetch(`${BASE_URL}/api/hotels/current`)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            dispatch(setHotelInfo({hotelInfo: data}));
        })
        .catch(err => console.log(err));
    };
};

export const getGalleries = () => {
    return (dispatch) => {
        return fetch(`${BASE_URL}/api/galleries`)
        .then((res) => res.json())
        .then((data) => dispatch(setGalleries({galleries: data.objects})))
        .catch((err) => console.log(err));
    }
};


export default hotelInfo;