import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import './style.scss';

import BreadCrumbs from 'components/breadcrumbs';
import {getRooms} from "../../reducers/rooms"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Slider from "../slider/index"

class Rooms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryId: null,
            names: null,
            rooms: null
        };
        this.rooms = React.createRef();
        this.names = React.createRef();
    }

    componentDidMount() {
        this.props.getRooms();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.rooms.objects && this.props.rooms.objects.length) {
            if (this.rooms.current && !this.state.rooms) {
                this.setState({
                    names: this.names.current,
                    rooms: this.rooms.current
                });
            }
        }
        if (this.props.language !== prevProps.language) {
            this.props.getRooms();
        }
    }

    onGalleryToggle = (i) => {
        this.setState({
            categoryId: i
        });
    };

    render() {
        const {rooms, t} = this.props;
        const id = parseInt(this.props.match.params.id);
        const {categoryId} = this.state;

        if (!rooms.objects) {
            return (
              <div className="route-page">Loading...</div>
            );
        }
        const category = rooms.objects.filter(cat => cat.id === categoryId)[0];

        if (id) {
            const idx = rooms.objects.findIndex(r => r.id === id);
            if (idx !== -1) {
                for (let i = 0; i < idx; i++) {
                    rooms.objects.push(rooms.objects.shift());
                }
            }
        }

        const categories = rooms.objects.map((c) => ({...c, key: c.id}));
        if (categories.length == 3) {
            categories.push(...categories.map((c) => ({...c, key: c.id + 100})));
        } else if (categories.length == 2) {
            categories.push(...categories.map((c) => ({...c, key: c.id + 100})),
                            ...categories.map((c) => ({...c, key: c.id + 200})));
        }

        const nameSliderSettings = {
            arrows: false,
            slidesToShow: 3,
            asNavFor: this.rooms.current,
            focusOnSelect: true,
            centerMode: true,
            className: 'names-slider'
        };

        const roomSliderSettings = {
            asNavFor: this.names.current,
            adaptiveHeight: true
        };

        return (
            <section className="rooms route-page">
                {category && (
                  <section className="gallery">
                    <Slider>
                        {category.gallery.map((gal) => {
                            return  (
                              <Slider key={gal.src}>
                                <img alt={category.title} src={gal.src} width="300px"/>
                              </Slider>
                            );
                        })}
                    </Slider>
                    <div className="close" onClick={this.onGalleryToggle}>
                        <FontAwesomeIcon icon={['fas', 'times']}/>
                    </div>
                  </section>
                )}
                <BreadCrumbs title={t('Rooms')}/>
                <section className="categories-wrapper">
                    <Slider sliderSettings={nameSliderSettings} setRef={this.names}>
                        {categories.map((category) => {
                            return (
                              <div className={'names-slide'} key={category.key}>
                                  <span>{category.title}</span>
                              </div>
                            );
                        })}
                    </Slider>
                    <Slider sliderSettings={roomSliderSettings} setRef={this.rooms}>
                        {categories.map((category) => {
                            const query = category.roomType
                              ? `?room-type=${category.roomType}`
                              : '';
                            const isSplit = Boolean(category.details2 || category.details3);
                            return <section className="special-cards" key={category.key}>
                                <div className="card">
                                    <div className={'card-img'}>
                                        <img alt={category.title} src={category.src} width="300px" />
                                    </div>
                                    <div className="info-wrapper">
                                        <div className="title">{category.title}</div>
                                        <div className="info" dangerouslySetInnerHTML={{__html: category.description}} />
                                        <div className={'app-content-slider__block-actions'}>
                                            {category.gallery.length > 0 && (
                                              <button className="read-more"
                                                onClick={this.onGalleryToggle.bind(this, category.id)}>
                                                  {t('Photo gallery')}
                                              </button>
                                            )}
                                        <Link to={`/booking${query}`} className="action">{t('Book')}</Link>
                                        </div>
                                    </div>
                                </div>
                                {!isSplit && (
                                  <div className={'card-details'}
                                     dangerouslySetInnerHTML={{__html: category.details}} />)}
                                {isSplit && (
                                  <div className={'card-details'}>
                                      {category.details && (
                                        <div dangerouslySetInnerHTML={{__html: category.details}} />)}
                                      {category.details2 && (
                                        <div dangerouslySetInnerHTML={{__html: category.details2}} />)}
                                      {category.details3 && (
                                        <div dangerouslySetInnerHTML={{__html: category.details3}} />)}
                                  </div>)}
                            </section>
                        })}
                    </Slider>
                </section>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rooms: state.rooms.rooms,
        hotel: state.hotelInfo.hotel,
        language: state.cache.language
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRooms: () => {
            dispatch(getRooms())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Rooms));
