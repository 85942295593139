import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"
import bem from 'b_';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Autosuggest from 'react-autosuggest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {withTranslation} from 'react-i18next';

import {getCities, getCountries, getUser, registerUser} from '../../actions';

import './style.scss';

const block = bem.with('app-form');

const getSuggestionValue = suggestion => suggestion.name;
const renderSuggestion = suggestion => {
    return (
      <span>{suggestion.name}</span>
    );
};

class Register extends Component {
    static defaultProps = {
        errors: {},
        token: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            language: 'ru',
            csrfmiddlewaretoken: '',
            confirm: false,
            country: '',
            city: ''
        };
        this.form = React.createRef();
    }

    componentDidMount() {
        const {user} = this.props;
        if (user) {
            this.props.history.push('/account');
        }
    }

    componentDidUpdate() {
        const {token, user} = this.props;
        if (user) {
            this.props.history.push('/account');
            return;
        }
        if (token !== this.state.csrfmiddlewaretoken) {
            this.setState({csrfmiddlewaretoken: token});
        }
    }

    renderErrors(name) {
        const {errors} = this.props;

        if (errors.hasOwnProperty(name)) {
          return errors[name].map((err) => {
              return (
                <span key={err} className={block('error')}>{err}</span>
              );
          });
        }
    }

    onSubmit = (event) => {
        event.preventDefault();
        const formData = new URLSearchParams();
        Object.entries(this.state).forEach(([key, value]) => {
            if (value instanceof Date) {
                value = `${value.getDate()}.${value.getMonth()+1}.${value.getFullYear()}`
            }
            formData.append(key, value);
        });
        this.props.registerUser(formData).then(() => {
            // this.props.getUser();
        });
    };

    handleChange = (date) => {
        this.setState({
          birthday: date
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    };

    filterPhoneKeys = (event) => {
        const alphabet = '+-()0123456789 ';
        if (alphabet.indexOf(event.key) === -1) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    onSuggestionsClearRequested = () => {};

    onCountrySuggestionsFetchRequested = ({value}) => {
        this.props.getCountries(value);
    };

    onCountrySuggestionSelected = (event, {suggestion, suggestionValue}) => {
        this.setState({
            country: suggestionValue,
            country_id: suggestion.id
        });
    };

    onCitySuggestionsFetchRequested = ({value}) => {
        this.props.getCities(value, this.state.country_id);
    };

    onCitySuggestionSelected = (event, {suggestionValue}) => {
        this.setState({
            city: suggestionValue
        });
    };

    render() {
        const {t} = this.props;
        const today = new Date();

        const countryInputProps = {
            onChange: this.handleInputChange,
            value: this.state.country,
            id: 'country',
            name: 'country',
            placeholder: t('Страна')
        };

        const cityInputProps = {
            onChange: this.handleInputChange,
            value: this.state.city,
            id: 'city',
            name: 'city',
            placeholder: 'Город'
        };

        return (
          <section className={'route-page'}>
            <section className="app-bread-crumbs">
                <ul>
                    <li><FontAwesomeIcon icon={['fas', 'chevron-right']}/></li>
                    <li><Link to="/">Главная</Link></li>
                    <li>/</li>
                    <li>Регистрация</li>
                </ul>
            </section>
            <div className={'heading'}>
                <h2>Регистрация</h2>
                <div className={'app-heading-title-text'}>
                    <span className={'app-heading-line-left'} />
                    <span className={'app-heading-line-text'}>Доступ к истории бронирований</span>
                    <span className={'app-heading-line-right'} />
                </div>
            </div>

            <div className={'app-register'}>
                <div className={'app-content-page'}>
                    <div className={'app-content-page__description'}>
                        <p>Сэкономите Ваше время при последующих бронированиях, создав учетную запись. Напоминаем, что для зарегистрированного гостя бронирование номера дает десятипроцентную скидку и&nbsp;гарантию минимально возможной цены. Пожалуйста, заполните прилагаемую ниже форму.</p>
                    </div>
                </div>
                <div className={block('container')}>
                    {this.props.user ? (
                      <div><Link to={'/account'}>Личный кабинет</Link></div>
                    ) : (
                    <form className={block()} method={'POST'} ref={this.form}
                          action={'/api/users/register'} onSubmit={this.onSubmit}>
                        <input type={'hidden'} name={'csrfmiddlewaretoken'}
                               value={this.state.csrfmiddlewaretoken} onChange={this.handleInputChange} />
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="first_name">Имя</label>
                            <div className={block('input')}>
                                <input id="first_name" name="first_name" placeholder="Имя" required type="text"
                                       value={this.state.first_name} onChange={this.handleInputChange} />
                                {this.renderErrors('first_name')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="last_name">Фамилия</label>
                            <div className={block('input')}>
                                <input id="last_name" name="last_name" placeholder="Фамилия" required type="text"
                                       value={this.state.last_name} onChange={this.handleInputChange} />
                                {this.renderErrors('last_name')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="email">Адрес электронной почты</label>
                            <div className={block('input')}>
                                <input id="email" name="email" placeholder="example@domain.com" required type="email"
                                       value={this.state.email} onChange={this.handleInputChange} />
                                {this.renderErrors('email')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="birthday">День рождения</label>
                            <div className={block('input')}>
                                <DatePicker selected={this.state.birthday} onChange={this.handleChange}
                                            dateFormat={'dd.MM.yyyy'}
                                            maxDate={today}
                                            value={this.state.birthday}
                                            customInput={(
                                    <input type="date" id="birthday" name={'birthday'} placeholder={'ДД.ММ.ГГГГ'} />
                                  )}/>
                                {this.renderErrors('birthday')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="language">Язык коммуникации</label>
                            <div className={block('input')}>
                                <select name="language" id='language'
                                        value={this.state.language} onChange={this.handleInputChange} >
                                    <option>Выберите язык коммуникации</option>
                                    <option value={'ru'}>Русский</option>
                                    <option value={'en'}>English</option>
                                </select>
                                {this.renderErrors('language')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="username">Имя пользователя</label>
                            <div className={block('input')}>
                                <input id="username" name="username" placeholder="Имя пользователя" required type="text"
                                       value={this.state.username} onChange={this.handleInputChange} />
                                {this.renderErrors('username')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="password1">Пароль</label>
                            <div className={block('input')}>
                                <input type="password" id="password1" name="password1" placeholder="Пароль" required
                                       value={this.state.password1} onChange={this.handleInputChange} />
                                {this.renderErrors('password1')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="password2">Повторите пароль</label>
                            <div className={block('input')}>
                                <input type="password" id="password2" name="password2" placeholder="Повторите пароль" required
                                       value={this.state.password2} onChange={this.handleInputChange} />
                                {this.renderErrors('password2')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="country">Страна</label>
                            <div className={block('input')}>
                                <Autosuggest
                                    suggestions={this.props.countries}
                                    onSuggestionsFetchRequested={this.onCountrySuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    onSuggestionSelected={this.onCountrySuggestionSelected}
                                    inputProps={countryInputProps}
                                  />
                                {this.renderErrors('country')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="city">Город</label>
                            <div className={block('input')}>
                                <Autosuggest
                                    suggestions={this.props.cities}
                                    onSuggestionsFetchRequested={this.onCitySuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    onSuggestionSelected={this.onCitySuggestionSelected}
                                    inputProps={cityInputProps}
                                  />
                                {this.renderErrors('city')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="address">Адрес</label>
                            <div className={block('input')}>
                                <input id="address" name="address" placeholder="Адрес" type="text"
                                       value={this.state.address} onChange={this.handleInputChange} />
                                {this.renderErrors('address')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <label className={block('label')} htmlFor="phone">Телефон</label>
                            <div className={block('input')}>
                                <input id="phone" name="phone" placeholder="Телефон" type="tel"
                                       value={this.state.phone} onChange={this.handleInputChange}
                                       onKeyPress={this.filterPhoneKeys}/>
                                {this.renderErrors('phone')}
                            </div>
                        </div>
                        <div className={block('group')}>
                            <div className={block('input', {type: 'checkbox'})}>
                                <input id="confirm" name="confirm" required type="checkbox"
                                       checked={this.state.confirm} onChange={this.handleInputChange} />
                                <label className={block('label')} htmlFor="confirm">Я даю согласие на обработку персональных данных</label>
                            </div>
                        </div>
                        <div className={`${block('group', {actions: true})} app-content-slider__block-actions app-content-slider__block-actions__distribute`}>
                            <button className="action">Зарегистрироваться</button>
                        </div>
                        <span>Уже есть акаунт? <Link to="/login">Войдите</Link></span>
                    </form>
                    )}
                </div>
            </div>
          </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        errors: state.user.errors,
        token: state.user.token,
        countries: state.cache.countries,
        cities: state.cache.cities
    };
};

const mapDispatchToProps = {
    getUser,
    registerUser,
    getCountries,
    getCities
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Register));