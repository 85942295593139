import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Login from './components/login';
import Register from './components/register';
import Account from './components/account';
import HomePage from './components/home';
import Location from './components/location';
import Rooms from './components/rooms';
import Hours from './components/hours';
import SpecialOffers from './components/special-offers';
import TravelLine from './components/travelline';
import ContentPage from 'components/content';
import Gallery from 'containers/gallery';

export const AppRouting = () => (
    <Switch>
        <Route path="/login" component={Login}/>
        <Route path="/register" component={Register}/>
        <Route path="/account" component={Account}/>
        <Route path="/location" component={Location}/>
        <Route path="/rooms/:id*" component={Rooms}/>
        <Route path="/special-offers" component={SpecialOffers}/>
        <Route path="/gallery" component={Gallery}/>
        <Route path={['/booking', '/en/booking']} component={TravelLine}/>
        <Route path="/hours" component={Hours}/>
        <Route path="/:page+" component={ContentPage}/>
        <Route path="/" component={HomePage}/>
    </Switch>
);