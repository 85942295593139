import React from 'react';
import SliderSlick from 'react-slick';
import bem from 'b_';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './style.scss';

const block = bem.with('app-slider');


const NextArrow = (props) => {
    const {style, onClick} = props;
    return (
        <div
            className={`${block('right')}`}
            style={{...style}}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={['fas', 'chevron-circle-right']}/>
        </div>
    );
};

const PrevArrow = (props) => {
    const {style, onClick} = props;
    return (
        <div
            className={`${block('left')}`}
            style={{...style}}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={['fas', 'chevron-circle-left']}/>
        </div>
    );

};

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
};

export default class Slider extends React.Component {
    static defaultProps = {
        sliderSettings: {},
        setRef: React.createRef()
    };
    render() {
        return (
            <div className={block()}>
                <SliderSlick {...{...settings, ...this.props.sliderSettings}}
                             ref={this.props.setRef}>
                    {this.props.children}
                </SliderSlick>
            </div>
        );
    }
}

