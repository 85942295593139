import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import bem from "b_";

import "./style.scss";

import BreadCrumbs from "components/breadcrumbs";
import { getPageContent } from "actions/index";
import { Link } from "react-router-dom";
import TitleText from "components/title-text";
import Gallery from "components/gallery";

const block = bem.with("app-content-page");

class ContentPage extends Component {
  renderCrumb(item) {
    return (
      <Fragment>
        {item.parent && this.renderCrumb(item.parent)}
        <li>/</li>
        <li>
          <Link to={`/${item.code}`}>{item.title}</Link>
        </li>
      </Fragment>
    );
  }

  render() {
    const { content, t } = this.props;
    if (!content) {
      return <div className={"route-page"}>Loading...</div>;
    }

    return (
      <section className={`route-page ${block("wrapper")}`}>
        <BreadCrumbs title={content.title} />
        <section className={block()}>
          <div className={block("container")}>
            <div className={block("text")}>
              {!content.src && (
                <div className="heading">
                  <h2>{content.title}</h2>
                  {content.subtitle && (
                    <TitleText>{content.subtitle}</TitleText>
                  )}
                </div>
              )}
              <div
                className={block("description")}
                dangerouslySetInnerHTML={{ __html: content.description }}
              />
            </div>
            {content.src && (
              <div className={block("image")}>
                <img
                  src={content.src}
                  title={content.title}
                  alt={content.title}
                />
              </div>
            )}
          </div>
          {content.files && content.files.length > 0 && (
            <div className={block("description")}>
              <div
                className={
                  "app-content-slider__block-actions app-content-slider__block-actions_distribute"
                }
              >
                {content.files.map(file => (
                  <a
                    key={file.id}
                    className={"read-more"}
                    href={file.src}
                    title={file.title}
                    target={"_blank"}
                  >
                    {file.title}
                  </a>
                ))}
              </div>
            </div>
          )}
        </section>
        {content.offers && content.offers.length > 0 && (
          <section className="special-cards-wrapper">
            <div className="heading">
              <h2>{t("Special offers")}</h2>
              {content.offers_title && (
                <div className={"app-heading-title-text"}>
                  <span className={"app-heading-line-left"} />
                  <span className={"app-heading-line-text"}>
                    {content.offers_title}
                  </span>
                  <span className={"app-heading-line-right"} />
                </div>
              )}
            </div>
            <section className="special-cards">
              {content.offers.map(offer => {
                return (
                  <div className={"card"} key={offer.id}>
                    <div className={"card-img"}>
                      <Link to={offer.link}>
                        <img alt={offer.title} src={offer.src} width="300px" />
                      </Link>
                    </div>
                    <div className="info-wrapper">
                      <div className={"card-content"}>
                        <div className={"card-text"}>
                          <div style={{ width: 100 + "%" }}>
                            <div className="title">
                              <Link to={offer.link}>{offer.title}</Link>
                            </div>
                            <div
                              className="info"
                              dangerouslySetInnerHTML={{
                                __html: offer.description
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
          </section>
        )}
        <Gallery gallery={content.images} showTitle={false} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    content: state.page.content
  };
};

const mapDispatchToProps = {
  getPageContent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ContentPage));
