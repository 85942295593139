import React from 'react';

import Slider from 'components/slider';


export default class Gallery extends React.Component {
  static defaultProps = {
    gallery: [],
    sliderSettings: {},
    title: 'Фотогалерея',
    subtitle: 'Узнайте о нас больше',
    showTitle: true
  };

  render() {
    const {gallery, sliderSettings, showTitle, title, subtitle} = this.props;

    if (!gallery || gallery.length === 0) {
      return null;
    }

    return (
      <section className="photo-galery">
        {showTitle && (
          <div className="heading">
            <h2>{title}</h2>
            {subtitle && (
              <div className={'app-heading-title-text'}>
                <span className={'app-heading-line-left'} />
                <span className={'app-heading-line-text'}>{subtitle}</span>
                <span className={'app-heading-line-right'} />
              </div>
            )}
          </div>
        )}
        <Slider sliderSettings={sliderSettings}>
          {gallery.map(g => {
            return (
              <img alt={g.title || title} src={g.src} key={g.src}/>
            );
          })}
        </Slider>
      </section>
    );
  }
}