import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';

import bem from 'b_';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const block = bem.with('app-bread-crumbs');

class BreadCrumbs extends Component {
  static defaultProps = {
    title: '',
    parent: undefined
  };

  renderCrumb(item) {
      return (
        <Fragment>
            {item.parent && this.renderCrumb(item.parent)}
            <li>/</li>
            <li><Link to={`/${item.code}`}>{item.title}</Link></li>
        </Fragment>
      );
  }

  render() {
    const {title, parent, t} = this.props;
    return (
      <section className={block()}>
          <ul>
              <li><FontAwesomeIcon icon={['fas', 'chevron-right']}/></li>
              <li><Link to="/">{t('Main page')}</Link></li>
              {parent && this.renderCrumb(parent)}
              <li>/</li>
              <li>{title}</li>
          </ul>
      </section>
    );
  }
}

export default withTranslation()(BreadCrumbs);