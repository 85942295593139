import React, {Component} from 'react';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import bem from 'b_';


import './style.scss';
import map from 'images/map.png';

const block = bem.with('map');


class Map extends Component {
  static defaultProps = {
    defaultZoom: 16,
    mapLocation: {
      lat: 55.584222,
      lng: 37.385475
    }
  };

  render() {
    return (
      <GoogleMap
          defaultZoom={this.props.defaultZoom}
          defaultCenter={this.props.mapLocation}
      >
          <Marker position={this.props.mapLocation} />
      </GoogleMap>
    );
  }
}


const MapElement = withScriptjs(withGoogleMap(Map));


class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch (error, info) {
    console.error(error);
    console.log(info);
    this.setState({hasError: true});
  }

  render() {
    const {hasError} = this.state;
    return (
      <div className={block('container', {static: hasError})}>
        {hasError ? (
          <img src={map} alt={'Static map'} />
        ) : (
          <MapElement {...this.props} />
        )}
      </div>
    );
  }
}


export default MapContainer;
