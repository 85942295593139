import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import bem from "b_";
import { withTranslation } from "react-i18next";

import "./style.scss";
import Slider from "components/slider";
import Map from "components/map";
import Gallery from "components/gallery";
import BreadCrumbs from "components/breadcrumbs";

const block = bem.with("app-content-slider");

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      names: null,
      rooms: null
    };
    this.rooms = React.createRef();
    this.names = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.hotelInfo &&
      this.props.hotelInfo.rooms &&
      this.props.hotelInfo.rooms.length
    ) {
      if (this.rooms.current && !this.state.rooms) {
        this.setState({
          names: this.names.current,
          rooms: this.rooms.current
        });
      }
    }
  }

  renderTitleText(text) {
    if (text) {
      return (
        <div className={"app-heading-title-text"}>
          <span className={"app-heading-line-left"} />
          <span className={"app-heading-line-text"}>{text}</span>
          <span className={"app-heading-line-right"} />
        </div>
      );
    }
  }

  renderSubtitle(text) {
    return (
      <div className={"sub-title"}>
        <div className={"sub-child-title"}>{text}</div>
      </div>
    );
  }

  render() {
    const { hotelInfo, t } = this.props;
    if (!hotelInfo) {
      return <div className="route-page">Loading...</div>;
    }
    const info = hotelInfo;
    const sliderSettings = {
      adaptiveHeight: true
    };
    const nameSliderSettings = {
      arrows: false,
      slidesToShow: 3,
      asNavFor: this.rooms.current,
      focusOnSelect: true,
      centerMode: true,
      className: "names-slider"
    };
    const roomSliderSettings = {
      asNavFor: this.names.current
    };
    const categories = info.rooms;
    if (categories) {
      categories.map((c) => c.key = c.id);
      if (categories.length == 3) {
        categories.push(...categories.map((c) => ({...c, key: c.id + 100})));
      } else if (categories.length == 2) {
        categories.push(...categories.map((c) => ({...c, key: c.id + 100})),
                        ...categories.map((c) => ({...c, key: c.id + 200})));
      }
    }

    return (
      <div>
        <BreadCrumbs title={t("About")} />
        <section className="home-block-wrapper">
          <div className="block-wrapper">
            <div className="home-content-block">
              <div className="home-block-text">
                {info.subtitle && (
                  <div className="heading">
                    <h2>{info.subtitle}</h2>
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: info.shortInfo }} />
              </div>
              <div className="home-map-container">
                <LazyLoad height={400}>
                  <Map
                    googleMapURL={this.props.googleMapURL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={
                      <div
                        style={{
                          height: "100%",
                          minWidth: "350px",
                          minHeight: "400px"
                        }}
                      />
                    }
                    mapElement={<div style={{ height: `100%` }} />}
                    mapLocation={info.mapLocation}
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
        </section>

        {categories && (
          <section className="app-content">
            <div className="heading">
              <h2>
                <Link to={"/rooms"}>{t("Hotel rooms")}</Link>
              </h2>
              {this.renderTitleText(t("For best price - book here"))}
            </div>
            <div className="app-content-block-wrapper slider-block">
              <Slider sliderSettings={nameSliderSettings} setRef={this.names}>
                {categories.map(category => {
                  return (
                    <div className={"names-slide"} key={category.key}>
                      <span>{category.title}</span>
                    </div>
                  );
                })}
              </Slider>
              <Slider sliderSettings={roomSliderSettings} setRef={this.rooms}>
                {categories.map(category => {
                  const query = category.roomType
                    ? `?room-type=${category.roomType}`
                    : "";
                  const roomURL = `/rooms/${category.id}`;
                  return (
                    <LazyLoad height={343} key={category.key}>
                      <div>
                        <div className={block("block")}>
                          <div className={block("block-img")}>
                            <img alt={category.title} src={category.src} />
                          </div>
                          <div className={block("block-text-wrapper")}>
                            <div className={block("block-text")}>
                              <h3>{category.title}</h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: category.description
                                }}
                              />
                              <div className={block("block-actions")}>
                                <Link to={roomURL} className={"read-more"}>
                                  {t("Details")}
                                </Link>
                                <Link
                                  to={`/booking${query}`}
                                  className={"action"}
                                >
                                  {t("Book")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </LazyLoad>
                  );
                })}
              </Slider>
            </div>
          </section>
        )}
        {info.offers && (
          <LazyLoad height={300}>
            <section className="special-cards-wrapper">
              <div className="heading">
                <h2>
                  <Link to={"/special-offers"}>{t("Special offers")}</Link>
                </h2>
                {this.renderTitleText(t("By hotel and restaurant"))}
              </div>
              <div className={"special-cards"}>
                {info.offers.map(offer => {
                  return (
                    <div className={"card"} key={offer.id}>
                      <div className={"card-img"}>
                        <Link to={offer.link}>
                          <img
                            alt={offer.title}
                            src={offer.src}
                            width="300px"
                          />
                        </Link>
                      </div>
                      <div className="info-wrapper">
                        <div className={"card-content"}>
                          <div className={"card-text"}>
                            <div style={{ width: 100 + "%" }}>
                              <div className="title">
                                <Link to={offer.link}>{offer.title}</Link>
                              </div>
                              <div
                                className="info"
                                dangerouslySetInnerHTML={{
                                  __html: offer.description
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </LazyLoad>
        )}

        {info.ideas && info.ideas.length && (
          <LazyLoad height={400}>
            <section className="ideas">
              <Slider sliderSettings={sliderSettings}>
                {info.ideas.map((idea, i) => {
                  return (
                    <Fragment key={idea.id}>
                      <div className={"heading"}>
                        <h2>
                          <Link to={`/${idea.page.code}`}>{idea.title}</Link>
                        </h2>
                        {this.renderTitleText(idea.subtitle)}
                      </div>
                      <section className="special-cards">
                        <div className="card">
                          <div className={"card-img"}>
                            <Link to={`/${idea.page.code}`}>
                              <img
                                alt={idea.title}
                                src={idea.src}
                                width="300px"
                              />
                            </Link>
                          </div>
                          <div className="info-wrapper">
                            <div className={"card-content"}>
                              <div
                                className="info"
                                dangerouslySetInnerHTML={{
                                  __html: idea.description
                                }}
                              />
                              {idea.page && (
                                <div
                                  className={block("block-actions", {
                                    right: true
                                  })}
                                >
                                  <Link
                                    to={`/${idea.page.code}`}
                                    className={"read-more"}
                                  >
                                    {t("Details")}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                    </Fragment>
                  );
                })}
              </Slider>
            </section>
          </LazyLoad>
        )}

        {info.images && info.images.length && (
          <LazyLoad height={600}>
            <Gallery
              gallery={info.images}
              title={t("Photo gallery")}
              subtitle={t("Learn more about us")}
            />
          </LazyLoad>
        )}
      </div>
    );
  }
}

Content.defaultProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=AIzaSyD5Fdc8qiUCgD9IJz2wMaPMSPR6AO4idwk&v=3.exp&libraries=geometry,drawing,places`
};

const mapStateToProps = state => {
  return {
    hotelInfo: state.hotelInfo.hotelInfo,
    hotel: state.hotelInfo.hotel
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Content));
