import React from 'react';


export default class Script extends React.PureComponent {
  static defaultProps = {
    src: undefined,
    async: true,
    type: 'text/javascript'
  };

  constructor(props) {
    super(props);
    this.script = document.createElement('script');
  }

  componentDidMount() {
    this.script.src = this.props.src;
    this.script.async = this.props.async;
    this.script.type = this.props.type;
    document.body.appendChild(this.script);
  }

  componentWillUnmount() {
    this.script.parentElement.removeChild(this.script);
    this.script = document.createElement('script');
  }

  render() {
    return null;
  }
}