import update from 'react-addons-update';

import {actionTypes} from '../store';

const initState = {
  user: undefined,
  errors: {},
  token: '',
};

const user = (state = initState, action) => {
  if (action.type === actionTypes.SET_USER) {
    const {user, errors, token} = action.payload;
    const patch = {
      user: {$set: user}
    };
    if (errors !== undefined) {
      patch.errors = {$set: errors};
    }
    if (token !== undefined) {
      patch.token = {$set: token};
    }
    return update(state, patch);
  } else {
    return state;
  }
};

export default user;