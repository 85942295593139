import {actionTypes, BASE_URL} from "../store";
import {setBanners, setOffers} from "../actions/index";

const initState = {
    offers: {},
    banners: {}
};

const offers = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_OFFERS:
            return {...state,  offers: action.offers};
        case actionTypes.SET_BANNERS:
            return {...state,  banners: action.banners};
        default:
            return state
    }
};

export const getOffers = () => dispatch => {
    fetch(`${BASE_URL}/api/hotels/current/offers`)
        .then(res => res.json())
        .then(data => {
            dispatch(setOffers(data))
        })
        .catch(err => console.log(err))
};

export const getBanners = () => dispatch => {
    fetch(`${BASE_URL}/api/hotels/current/banners`)
        .then(res => res.json())
        .then(data => {
            dispatch(setBanners(data))
        })
        .catch(err => console.log(err))
};

export default offers