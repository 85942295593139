import React from 'react';


export default class LiveChat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.script = document.createElement('script');
    this.script.src = '/js/livechat.js';
    this.script.async = true;
    this.script.type = 'text/javascript';
  }

  componentDidMount() {
    document.body.appendChild(this.script);
  }

  render() {
    return null;
  }
}